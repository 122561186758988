import * as React from "react";

import styled from "../../theme/styled";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontWeightHelper
} from "../../theme/theme-helper";
import ArticlesGrid, { IArticleData } from "../articles-grid/articles-grid";
import { ColumnWidthWrapper } from "../confines-wrappers";
import { IGradients } from "../gradients/gradient-list";
import { HeadingUnderlined } from "../heading";
import { HeadingTag } from "../heading/heading-underlined";
import Link from "../link/link";
import Panel from "../panel-wrapper";

interface IProps {
  headingTag?: HeadingTag;
  title: string;
  articles: IArticleData[];
  gradient: keyof IGradients;
  sideText?: string;
}

const InsightsContainer = styled("div")`
  overflow: hidden;
  color: white;

  img {
    aspect-ratio: 1.5;
    object-fit: cover;
  }

  .articles-grid-container {
    padding-top: 10%;

    .article-wrapper {
      .article {
        .heading {
          .title {
            a {
              color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "white"
    })};

              &:hover {
                border-bottom-color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "white"
    })};
              }
            }
          }

          .authored-on,
          .authored-by {
            color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
          }
        }

        .image {
          box-shadow: 0.8rem 0.8rem 0 0 rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  .section-cta-container {
    padding-top: 6.8rem;
    width: 100%;

    p {
      padding-top: 0;
      text-align: center;
      font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "body_1"
  })};
      font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "bold"
    })};
      line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};

      a {
        color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
        border-bottom-color: rgba(255, 255, 255, 0.4);

        &:hover {
          border-bottom-color: rgba(255, 255, 255, 1);
        }
      }
    }
  }

  @media (min-width: 480px) {
    .section-cta-container {
      padding-top: 8.8rem;
    }
  }

  @media (min-width: 960px) {
    .articles-grid-container {
      padding-top: 8%;
    }

    .section-cta-container {
      p {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
      }
    }
  }

  @media (min-width: 1440px) {
    .section-cta-container {
      padding-top: 10.2rem;
    }
  }
`;

const Insights = ({
  title, headingTag, articles, gradient, sideText
}: IProps) => {
  let headingTagSplit: string[] = [];
  let headingNumber = 0;
  let subHeadingTag = "h3";

  headingTag = headingTag || "h2";
  headingTagSplit = headingTag.split("");
  headingNumber = parseInt(headingTagSplit[ 1 ], 0) + 1;
  subHeadingTag = `h${headingNumber.toString()}` || "h3";

  return (
    <InsightsContainer className="insights-container">
      <Panel
        className="content-container"
        // gradient={gradient}
        backgroundColor="black"
      >
        <ColumnWidthWrapper
          className="latest-wrapper"
          sideText={sideText}
        >
          <HeadingUnderlined
            headingTag={headingTag}
            title={title}
            textColor="white"
            hrColor="white"
          />

          <ArticlesGrid
            articles={articles}
            headingTag={subHeadingTag}
          />

          <div className="section-cta-container">
            <p className="insights-link">
              <span className="off-screen">
                Click here to
                {" "}
              </span>

              <Link
                type="styled"
                color="white"
                href="/insights"
              >
                Read our Insights
              </Link>
            </p>
          </div>
        </ColumnWidthWrapper>
      </Panel>
    </InsightsContainer>
  );
};

export default Insights;
