import React from "react";

import {
  IFluid,
  ILocalFile
} from "src/types/local-files";

import styled from "../../theme/styled";
import ArticleTeaser from "../article-teaser";
import { FullWidthColumnWrapper } from "../confines-wrappers";

interface IProps {
  articles: IArticleData[];
  headingTag?: string;
}

export interface IArticleData {
  created: string;
  path: string;
  title: string;
  author?: string;
  cover: ILocalFile<IFluid>;
  headingTag?: string;
}

const ArticlesGridContainer = styled.div`
  transition: 0.2s ease-in-out;

  .articles-grid-wrapper {
    display: flex;
    flex-direction: column;

    .article-container {
      transition: 0.2s ease-in-out;
      padding-top: 16%;

      &:first-child {
        padding-top: 0;
      }
    }
  }

  @media (min-width: 568px) {
    .articles-grid-wrapper {
      .article-container {
        padding-top: 8.8rem;
      }
    }
  }

  @media (min-width: 600px) {
    .articles-grid-wrapper {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;

      .article-container {
        padding-top: 6.8rem;
        width: 50%;

        &:nth-child(-n + 2) {
          padding-top: 0;
        }

        &:nth-child(odd) {
          padding-right: 2%;

          &:last-child {
            padding-left: 1%;
            padding-right: 1%;
          }
        }

        &:nth-child(even) {
          padding-left: 2%;
        }
      }
    }
  }

  @media (min-width: 960px) {
    .articles-grid-wrapper {
      .article-container {
        padding-top: 8.2rem;
        width: 33.33%;

        &:nth-child(-n + 3) {
          padding-top: 0;
        }

        &:nth-child(3n + 1) {
          padding-left: 0;
          padding-right: 2%;

          &:last-child {
            padding-left: 1%;
            padding-right: 1%;
          }
        }

        &:nth-child(3n + 2) {
          padding-left: 1%;
          padding-right: 1%;

          &:last-child {
            padding-left: 2%;
            padding-right: 0;
          }
        }

        &:nth-child(3n + 3) {
          padding-left: 2%;
          padding-right: 0;

          &:last-child {
            padding-left: 2%;
            padding-right: 0;
          }
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .articles-grid-wrapper {
      .article-container {
        padding-top: 9.8rem;
      }
    }
  }

  @media (min-width: 1440px) {
    .articles-grid-wrapper {
      .article-container {
        padding-top: 12.2rem;
      }
    }
  }
`;

const ArticlesGrid = ({ articles, headingTag }: IProps) => {
  return (
    <ArticlesGridContainer className="articles-grid-container">
      <FullWidthColumnWrapper className="articles-grid-wrapper">
        {articles.map((article: IArticleData, i) => {
          return (
            <ArticleTeaser
              key={i}
              created={article.created}
              path={article.path}
              title={article.title}
              htmlHeadingTag={headingTag}
              author={article.author}
              cover={article.cover}
            />
          );
        })}
      </FullWidthColumnWrapper>
    </ArticlesGridContainer>
  );
};

export default ArticlesGrid;
